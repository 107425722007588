import { Screenings } from '../enumerations/enums';

export const screeningNames = {
  [Screenings.YOUTH_PHQ2]: 'Youth PHQ-2',
  [Screenings.YOUTH_NEEDS]: 'Youth Needs',
  [Screenings.YOUTH_UCLA3]: 'Youth UCLA-3',
  [Screenings.ADULT_PHQ4]: 'PHQ-4 Screening',
  [Screenings.ADULT_NEEDS]: 'Social Determinants of Health',
  [Screenings.ADULT_PRAPARE]: 'Social Determinants of Health - PRAPARE',
  [Screenings.ADULT_EP_PRAPARE]: 'Social Determinants of Health - EP PRAPARE',
  [Screenings.ADULT_UCLA3]: 'UCLA-3 Screening',
  [Screenings.ADULT_WELLBEING]: 'Well-Being Assessment',
  [Screenings.CAHPS_PYXPLUS]: 'Pyx Plus Survey - CAHPS',
};

export const screeningNamesShortened = {
  [Screenings.YOUTH_PHQ2]: 'Youth_PHQ2',
  [Screenings.YOUTH_NEEDS]: 'YouthAssessment',
  [Screenings.YOUTH_UCLA3]: 'Youth_UCLA3',
  [Screenings.ADULT_PHQ4]: 'PHQ',
  [Screenings.ADULT_NEEDS]: 'SDOH',
  [Screenings.ADULT_PRAPARE]: 'PRAPARE',
  [Screenings.ADULT_UCLA3]: 'UCLA',
  [Screenings.ADULT_WELLBEING]: 'WELLBEING',
  [Screenings.CAHPS_PYXPLUS]: 'CAHPS-PYXPLUS',
  [Screenings.ADULT_EP_PRAPARE]: 'EP-PRAPARE',
};
