import AdultNeeds from '../ScreeningImplementations/AdultNeeds/HistoryItem/AdultNeeds';
import AdultPHQ4 from '../ScreeningImplementations/AdultPHQ4/HistoryItem/AdultPHQ4';
import AdultPRAPARE from '../ScreeningImplementations/AdultPRAPARE/HistoryItem/AdultPRAPARE';
import AdultEPPRAPARE from '../ScreeningImplementations/AdultEPPRAPARE/HistoryItem/AdultEPPRAPARE';
import AdultUCLA3 from '../ScreeningImplementations/AdultUCLA3/HistoryItem/AdultUCLA3';
import YouthNeeds from '../ScreeningImplementations/YouthNeeds/HistoryItem/YouthNeeds';
import YouthPHQ2 from '../ScreeningImplementations/YouthPHQ2/HistoryItem/YouthPHQ2';
import YouthUCLA3 from '../ScreeningImplementations/YouthUCLA3/HistoryItem/YouthUCLA3';
import AdultWellBeing from '../ScreeningImplementations/AdultWellBeing/HistoryItem/AdultWellBeing';
import CAHPSPyxPlus from '../ScreeningImplementations/CAHPSPyxPlus/HistoryItem/CAHPSPyxPlus';
import { Screenings } from '../enumerations/enums';

export const HistoryItems = {
  [Screenings.YOUTH_NEEDS]: YouthNeeds,
  [Screenings.YOUTH_PHQ2]: YouthPHQ2,
  [Screenings.YOUTH_UCLA3]: YouthUCLA3,
  [Screenings.ADULT_NEEDS]: AdultNeeds,
  [Screenings.ADULT_PHQ4]: AdultPHQ4,
  [Screenings.ADULT_UCLA3]: AdultUCLA3,
  [Screenings.ADULT_PRAPARE]: AdultPRAPARE,
  [Screenings.ADULT_EP_PRAPARE]: AdultEPPRAPARE,
  [Screenings.ADULT_WELLBEING]: AdultWellBeing,
  [Screenings.CAHPS_PYXPLUS]: CAHPSPyxPlus,
};
